<template>
  <b-card>
    <div class="d-flex my-2">
      <feather-icon icon="MessageCircleIcon" size="19" />
      <h4 class="mb-0 ml-50">PUSH NOTIFICATION</h4>
    </div>
    <validation-observer ref="notification">
      <form class="form__notification">
        <b-row>
          <b-col cols="12" md="3"> Notification Title </b-col>
          <b-col cols="12" md="9">
            <validation-provider #default="{ errors }" name="title" vid="title" rules="required">
              <b-form-input v-model="title" type="text" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3"> Notification Message </b-col>
          <b-col cols="12" md="9">
            <validation-provider #default="{ errors }" name="message" vid="message" rules="required">
              <!-- <vue-editor
                v-model="message"
                :editor-toolbar="customToolbar"
              /> -->

              <b-form-textarea v-model="message" rows="3" max-rows="6" />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3"> Delay Time (Optional) </b-col>
          <b-col cols="12" md="9" class="delay__time">
            <flat-pickr v-model="delayTime" class="form-control" :config="config" />

            <feather-icon v-if="delayTime" icon="XIcon" size="19" class="clear__icon" @click="clearTime" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3"> Deep Link (Optional) </b-col>
          <b-col cols="12" md="9">
            <b-form-input v-model="deepLink" type="text" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3"> Web Link (Optional) </b-col>
          <b-col cols="12" md="9">
            <b-form-input v-model="webLink" type="text" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3"> UserList (Optional) </b-col>
          <b-col cols="12" md="9">
            <div style="max-width: 400px">
              <ChooseListUser modal-type="add" @update-data="handleAddUserList" @upload-file="handleGetDataUpload" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3"> Content Flash Banner: </b-col>
          <b-col cols="12" md="9">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 ml-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleShowModalContent"
            >
              <template> Add Content </template>
            </b-button>
          </b-col>
        </b-row>

        <!-- CONTENT POPUP -->
        <b-card v-if="isShow" style="border: 1px solid black">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-1 mr-1" style="font-weight: 900">Content flash banner:</h4>
          </div>
          <div class="d-flex align-items-center">
            <p v-if="imageContent" class="mb-0" style="font-size: 16px; font-weight: bold; margin-right: 8px">Image:</p>
            <b-img
              v-if="imageContent"
              style="width: 30px; height: 30px; cursor: pointer"
              :src="imageContent"
              alt="logo"
            />
          </div>

          <p style="font-size: 16px"><span style="font-weight: bold">Title: </span>{{ titleContent || '' }}</p>
          <p style="font-size: 16px"><span style="font-weight: bold">Top text: </span>{{ topTextContent || '' }}</p>
          <p style="font-size: 16px">
            <span style="font-weight: bold">Middle text: </span>{{ middleTextContent || '' }}
          </p>
          <p style="font-size: 16px">
            <span style="font-weight: bold">Bottom text: </span>{{ bottomTextContent || '' }}
          </p>
          <p v-if="endTime" style="font-size: 16px">
            <span style="font-weight: bold">End time show banner: </span>{{ endTime || '' }}
          </p>
        </b-card>
        <p v-if="errorFlashPage" class="text-danger mt-2">Please add data of flash banner before confirm</p>
        <b-row>
          <b-col cols="12" class="flex__end">
            <b-button class="ms-auto" variant="primary" @click="showModal"> Push Notification </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

    <b-modal
      ref="modal"
      title="Push Notification"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loading"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title"> Are you sure you want to push this notification ? </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loading" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- MODAL CHANGE CONTENT POPUP -->
    <b-modal
      ref="content"
      hide-footer
      title="Change Text Content"
      centered
      cancel-variant="outline-secondary"
      size="xl"
    >
      <div style="color: black">
        <!-- TITLE -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Title:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="titleContent" type="text" placeholder="Add title..." />
          </b-col>
        </b-row>

        <!-- Top text -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Top text:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <div style="height: 150px; overflow-y: hidden; ">
              <vue-editor v-model="topTextContent" :editorOptions="editorOptions" show-preview="true" style="height: 100px;" />

            </div>
            <!-- <b-form-input v-model="topTextContent" type="text" placeholder="Add top text..." /> -->
          </b-col>
        </b-row>

        <!-- middle text -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Middle text:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column;">
            <div style="height: 150px; overflow-y: hidden;">
              <vue-editor v-model="middleTextContent"  :editorOptions="editorOptions" style="height: 100px;" />

            </div>
            <!-- <b-form-input v-model="middleTextContent" type="text" placeholder="Add top text..." /> -->
          </b-col>
        </b-row>
        <!-- bottom text -->
        <b-row class="mt-4">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bottom text:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="bottomTextContent" type="text" placeholder="Add top text..." />
          </b-col>
        </b-row>
        <!-- end time -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">End Time Show:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <flat-pickr
              v-model="endTime"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
                minDate: new Date(),
              }"
            />
          </b-col>
        </b-row>
        <!-- image -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Image:</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <div v-if="!imageContent" style="cursor: pointer" @click="$refs.refInputEl.$el.click()">
              <feather-icon icon="UploadIcon" size="16" style="margin-left: 8px" /> Upload
            </div>
            <div class="">
              <b-img
                v-if="imageContent"
                style="width: 30px; height: 30px; cursor: pointer; background-color: #2B65A5; border: 1px solid #2B65A5;"
                :src="imageContent"
                alt="logo"
                @click="$refs.refInputEl.$el.click()"
              />

            </div>

            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .gif, .heic, .jpeg"
              :hidden="true"
              plain
              style="cursor: pointer"
              @change="inputImageRenderer"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveChange"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BModal,
  BCardText,
  BFormTextarea,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Spinner from '@core/components/Spinner'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapState, mapActions } from 'vuex'
import useToastHandler from '@/services/toastHandler'
import { formatISO, formatDateSend } from '@/helpers/utils'
import ChooseListUser from '../comp/settings/ChooseListUser.vue'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    flatPickr,
    BButton,
    BModal,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormTextarea,
    BFormFile,
    ChooseListUser,
    BImg,
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
        
      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ 'color': [] }]
          ]
        },
        placeholder: 'Add text...',
        theme: 'snow', // or 'bubble'
        formats: [
          'bold', 'italic', 'underline', 'color', 'background'
        ],
        // Set default text color to black (#000000)
        styles: {
          '.ql-editor': {
            color: '#ffffff'
          }
        }
      },

      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        enableSeconds: true,
        minDate: new Date(),
      },
      title: '',
      message: '',
      delayTime: null,
      deepLink: null,
      webLink: null,
      required,

      listSeletedUser: [],
      csvData: [],
      formatCsvData: [],

      titleContent: '',
      topTextContent: '',
      middleTextContent: '',
      bottomTextContent: '',
      imageContent: process.env.VUE_APP_IMG_URL,
      endTime: '',

      isShow: false,
      errorFlashPage: false,
    }
  },
  computed: {
    ...mapState('notification', ['loading', 'isReset']),
  },
  methods: {
    ...mapActions({
      handlePushNotification: 'notification/handlePushNotification',
      resetListUser: 'notification/resetListUser',
      uploadFlag: 'sportComp/createComp/uploadFlag',
    }),

    clearTime() {
      this.delayTime = null
    },

    async showModal() {
      const valid = await this.$refs.notification.validate()
      if (!valid || !this.$refs.notification.flags.changed) return
      if (
        !this.imageContent &&
        !this.titleContent &&
        !this.topTextContent &&
        !this.middleTextContent &&
        !this.bottomText
      )
        return (this.errorFlashPage = true)
      this.$refs.modal.show()
    },
    async handleSubmitModal() {
      const configData = {
        deepLink: this.deepLink,
        weblink: this.webLink,
        png: {
          url: this.imageContent,
        },
        title: {
          value: this.titleContent,
        },
        topText: {
          value: this.topTextContent,
        },
        middleText: {
          value: this.middleTextContent,
        },
        bottomText: {
          value: this.bottomTextContent,
        },
      }

      const popup = {
        ...(this.endTime && { endDate: this.formatDateSend(this.endTime) }),
        type: 'FlashBanner',
        config: configData,
      }
      // HAVE USER LIST
      if (this.csvData.length > 0) {
        if (this.formatCsvData.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.formatCsvData) {
            const list = item.map(x => x.userId)
            // eslint-disable-next-line no-await-in-loop
            const result = await this.handlePushNotification({
              title: this.title,
              message: this.message,
              delayTime: this.delayTime ? formatISO(this.delayTime) : null,
              deepLink: this.deepLink,
              userIds: list,
              popup,
            })
            if (!result) return
          }

          this.showToastSuccess('Success', 'Create offer successfully!')
          this.resetData()

          if (!this.isReset) this.resetListUser(true)
          return
        }
        const arr = this.csvData.map(i => i.userId)
        const result = await this.handlePushNotification({
          title: this.title,
          message: this.message,
          delayTime: this.delayTime ? formatISO(this.delayTime) : null,
          deepLink: this.deepLink,
          userIds: arr,
          popup,
        })
        if (!result) return
        this.resetData()
        if (!this.isReset) this.resetListUser(true)
        return this.showToastSuccess('Notification', 'Pushed notification successfully')
      }

      const listUsers = this.listSeletedUser.map(i => i.userId)

      const result = await this.handlePushNotification({
        title: this.title,
        message: this.message,
        delayTime: this.delayTime ? formatISO(this.delayTime) : null,
        deepLink: this.deepLink,
        ...(listUsers.length > 0 && { userIds: listUsers }),
        popup,
      })
      if (!result) return
      this.resetData()
      if (!this.isReset) this.resetListUser(true)
      this.showToastSuccess('Notification', 'Pushed notification successfully')
    },
    splitArray(array) {
      const result = []
      const length = array.length
      for (let i = 0; i < length; i += 200) {
        result.push(array.slice(i, i + 200))
      }
      return result
    },
    resetData() {
      this.listSeletedUser = []
      this.csvData = []
      this.formatCsvData = []
      this.delayTime = null
      this.deepLink = null
      this.titleContent = ''
      this.topTextContent = ''
      this.middleTextContent = ''
      this.bottomTextContent = ''
      this.imageContent = process.env.VUE_APP_IMG_URL,
      this.endTime = ''
      this.isShow = false
    },
    handleAddUserList(data) {
      this.listSeletedUser = data
    },
    handleGetDataUpload(data) {
      this.csvData = data

      if (data.length > 200) {
        const splitArrays = this.splitArray(data)
        this.formatCsvData = splitArrays
      } else {
        this.formatCsvData = []
      }
    },
    handleShowModalContent() {
      this.$refs.content.show()
    },
    async inputImageRenderer(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          this.imageContent = res
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    handleSaveChange() {
      if (this.imageContent || this.titleContent || this.topTextContent || this.middleTextContent || this.bottomText)
        this.errorFlashPage = false
      this.$refs.content.hide()
      this.isShow = true
    },
  },
  setup() {
    const { showToastSuccess } = useToastHandler()

    return {
      showToastSuccess,
      formatISO,
      formatDateSend,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form__notification {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ms-auto {
  margin-left: auto;
}

.flex__end {
  display: flex;
  justify-content: end;
}

.text-title {
  color: #000;
}

.delay__time {
  position: relative;
}

.clear__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  cursor: pointer;
}
</style>
