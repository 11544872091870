<template>
  <div>

    <b-card v-if="dataRacing && dataSport && dataTipping">
      <!-- RACING -->
      <div class="d-flex my-2">
        <feather-icon
          icon="MessageCircleIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          RACING NOTIFICATION
        </h4>
      </div>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Title
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataRacing.dataNoti.title"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Message
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataRacing.dataNoti.message"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Deep Link
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataRacing.dataNoti.deepLink"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          Send Noti Before (minutes) First Leg Start
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataRacing.threshold"
            :formatter="formatNumber"
          />
        </b-col>
      </b-row>

      <!-- SPORT -->
      <div class="d-flex my-2">
        <feather-icon
          icon="MessageCircleIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          SPORT NOTIFICATION
        </h4>
      </div>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Title
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataSport.dataNoti.title"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Message
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataSport.dataNoti.message"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Deep Link
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataSport.dataNoti.deepLink"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          Send Noti Before (minutes) First Leg Start
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataSport.threshold"
            :formatter="formatNumber"
          />
        </b-col>
      </b-row>

      <!-- TIPPING -->
      <div class="d-flex my-2">
        <feather-icon
          icon="MessageCircleIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          TIPPING NOTIFICATION
        </h4>
      </div>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Title
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataTipping.dataNoti.title"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Message
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataTipping.dataNoti.message"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
        >
          Deep Link
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataTipping.dataNoti.deepLink"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          Send Noti Before (minutes) First Leg Start
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-input
            v-model="dataTipping.threshold"
            :formatter="formatNumber"
          />
        </b-col>
      </b-row>
      <p
        class="text-danger mt-2"
      >
        Please set minutes to 0 if you want to turn off notifications.
      </p>

      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end mr-4 mt-4">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="loadingSetting"
            @click="handleShowModalConfirm"
          >
            Save change
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      ref="modal"
      title="Confirm"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        Do you want to save this change?
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingSetting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BButton,
  BCard, BCardText, BCol, BFormInput, BRow, VBModal,
} from 'bootstrap-vue'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormInput,
    BCardText,
    BButton,
    Spinner,

  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      dataRacing: null,
      dataSport: null,
      dataTipping: null,
      loadingSetting: false,

    }
  },
  computed: {
    // ...mapState('races/compDetail', ['dataDetail']),
  },
  async mounted() {
    const res = await this.getSetting('config-comp-upcoming-send-noti')
    if (res.configs) {
      this.dataRacing = res.configs.classic
      this.dataSport = res.configs.sport
      this.dataTipping = res.configs.sportTipping
    }
  },

  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      setNewSettingUpdateProfileBetBack: 'setting/setting/setNewSettingUpdateProfileBetBack',
    }),
    formatNumber(value) {
      if (value < 0) return null
      return value
    },
    handleShowModalConfirm() {
      this.$refs.modal.show()
    },
    async handleSubmitModal() {
      this.loadingSetting = true
      const data = {
        value: {
          configs: {
            classic: this.dataRacing,
            sport: this.dataSport,
            'sport-tipping': this.dataTipping,
          },
        },
      }
      const res = await this.setNewSettingUpdateProfileBetBack({ key: 'config-comp-upcoming-send-noti', data })
      if (res) {
        this.$refs.modal.hide()
        this.showToastSuccess('Success', 'Save this config successfully')
      }
      this.loadingSetting = false
    },

  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

    <style lang="scss" scoped>
    .active-title {
      color: white;
    }

    .text-title {
      color: #000;
    }
    .w-full{
      width: 100%;
      display: block;
    }
    </style>
