<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>

      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Notification -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Push Noti</span>
          </template>

          <Notification
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: PUSH NOTI GROUP -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Push Noti Group</span>
          </template>
          <GroupNotification
            v-if="tabIndex === 1"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: NOTI SHOWDOWN -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="BookIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Noti For Showdown</span>
          </template>
          <NotiShowdown
            v-if="tabIndex === 2"
            class="mt-2 pt-75"
          />
        </b-tab>

      </b-tabs>
    </b-card>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BTab, BTabs, VBModal,
} from 'bootstrap-vue'
import { ENTRY_TYPE_BACK, ENTRY_TYPE_FRONT } from '@/@core/utils/enum/comp'
import useToastHandler from '@/services/toastHandler'
import Notification from './Notification.vue'
import GroupNotification from './GroupNotification.vue'
import NotiShowdown from './NotiShowdown.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    LoadingPage,

    Notification,
    GroupNotification,
    NotiShowdown,

  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,

    }
  },
  computed: {
    // ...mapState('races/compDetail', ['dataDetail']),
  },
  async mounted() {
    switch (this.$route.query.tab) {
      case 'group-noti':
        this.tabIndex = 1
        break
      case 'showdown-noti':
        this.tabIndex = 2
        break
      default:
        break
    }
  },

  methods: {
    ...mapActions({
    }),

  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      ENTRY_TYPE_FRONT,
      ENTRY_TYPE_BACK,
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .active-title {
    color: white;
  }

  .text-title {
    color: #000;
  }
  .w-full{
    width: 100%;
    display: block;
  }
  </style>
